<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Requisition No.</th>
              <th>Hub Zone</th>
              <th>Status</th>
              <th>Delivery Transfer Status</th>
              <th>Tracking Info</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requisition, index) in requisitions.data" :key="index">
              <td>{{ requisitions.from + index }}</td>
              <td>{{ requisition.requisition_number }}</td>
              <td>{{ requisition.hub.hub_zone.hub_zone_desc }}</td>
              <td>
                <a href="javascript: void(0);" v-b-modal.item-requisition-status-modal @click="$bvModal.show('item-requisition-status-modal'), status(requisition.max_item_requisition_status, requisition)" class="btn btn-sm btn-primary">
                  <i class="fe fe-eye"/>
                </a>
              </td>
              <td>
                <a-tag color="#f50" v-if="requisition.requisition_status.includes('REQUISITION_TRANSFER_FROM_TEJGAON_PENDING') && !requisition.requisition_status.includes('REQUISITION_TRANSFER_FROM_TEJGAON_COMPLETED')">
                  Pending
                </a-tag>
                <a-tag color="#87d068" v-if="requisition.requisition_status.includes('REQUISITION_TRANSFER_FROM_TEJGAON_PENDING') && requisition.requisition_status.includes('REQUISITION_TRANSFER_FROM_TEJGAON_COMPLETED')">
                  Dispatch
                </a-tag>
              </td>
              <td>
               <span style="font-weight: 600"> Track Number :</span> {{ requisition.requisition_tracking_number }} <br>
                <a :href="requisition.requisition_tracking_picture" target="_blank" download>
                  <img :src="requisition.requisition_tracking_picture" alt="" class="rounded" width="40" height="40"/>
                </a>
              </td>
              <td>{{ customDate(requisition.created_at) }}</td>
              <td>
                <router-link :to="{ name: 'hubViewRequisition', params: { requisition_id: requisition.id } }" class="btn btn-sm btn-info mr-2" tag="a">
                  <i class="fe fe-eye"/> View
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="requisitions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="requisitions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <status :current="current" :requisition="requisition"></status>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import Status from '@/views/requisition/partial/status'

export default {
  name: 'List',
  components: { Empty, Status },
  data() {
    return {
      selectedIds: [],
      selectAll: false,
      requisitions: {},
      requisition: {},
      loading: false,
      approveBtnLoading: false,
      btnLoading: false,
      flag: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      current: 0,
      search_data: {
        date_range: '',
      },
    }
  },
  mounted() {},
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    search() {
      this.flag = true
      this.loading = true
      this.btnLoading = true
      apiClient.post('api/hub/delivery/previous-requisition/search', this.search_data)
        .then(response => {
          this.btnLoading = false
          this.loading = false
          this.flag = true
          this.requisitions = response.data.requisitions
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      apiClient.post('api/hub/delivery/previous-requisition/search?page=' + page, this.search_data)
        .then(response => {
          this.requisitions = response.data.requisitions
        })
    },
    status(itemRequisitionStatus, requisition) {
      this.current = 0
      this.requisition = ''
      this.current = itemRequisitionStatus
      this.requisition = requisition
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
